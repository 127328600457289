import React, {PureComponent} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {meta} from 'config/constants';
import Layout from 'components/layouts/Curriculum';
import {HTMLContent} from 'components/Content';
import CurriculumBasis from 'components/organisms/curriculums/Basis';
import i18next from 'lib/i18next';

export default class CurriculumsIndex extends PureComponent {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render() {
    const {location, excerpt: description} = this.props;
    const {layout} = this.props.pageContext;
    const {html, frontmatter} = this.props.data.markdownRemark;
    const {title, updatedAt, thumbnail, curriculum} = frontmatter;
    const imageUrl = meta.images.url + thumbnail;
    const heading = i18next.t(`curriculums.${curriculum}`);
    return (
      <Layout index={<CurriculumBasis />} layout={layout}>
        <Helmet titleTemplate={`Webなりカリキュラム | %s `}>
          <title>{`${heading} | ${title} `}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={location.href} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
        </Helmet>

        <div className="curriculum-post">
          <div className="header">
            <h1 className="title">{title}</h1>
            <div className="subtitle">
              <p className="weak-text">最終更新日: {updatedAt}</p>
            </div>
          </div>
          <div className="body">
            <HTMLContent content={html} />
          </div>
        </div>
      </Layout>
    );
  }
}

export const CurriculumsIndexQuery = graphql`
  query CurriculumIndexQuery($id: String, $category: String) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      excerpt(truncate: true, pruneLength: 300)
      frontmatter {
        title
        thumbnail
        curriculum
        categories
        tags
        createdAt(formatString: "YYYY年 MM月DD日")
        updatedAt(formatString: "YYYY年 MM月DD日")
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___createdAt], order: DESC}
      filter: {frontmatter: {categories: {in: [$category]}}}
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            slug
            categories
            createdAt(formatString: "MMM DD, YYYY")
            updatedAt(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
