import React from 'react';
import Layout from '../../components/layouts/Index';

class CurriculumLayout extends React.PureComponent {
  render() {
    const {amp, baseUrl} = this.props;
    return (
      <Layout amp={amp} baseUrl={baseUrl}>
        <div className="row curriculum-container">
          <div className="row flex">
            <div className="col l4 hide-on-med-and-down" style={{padding: "unset"}}>
              <div className="sidebar">{this.props.index}</div>
            </div>
            <div className="col s12 l8" style={{padding: "unset"}}>
              <main className="main">{this.props.children}</main>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CurriculumLayout;
