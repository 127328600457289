import React, {PureComponent} from 'react';
import {curriculumBasisPath} from 'lib/routes';

export default class Basis extends PureComponent {
  render() {
    return (
      <div className="sidebar-content">
        <div>
          <h2 className="title">IT基礎</h2>
        </div>
        <div>
          <p className="index">目次</p>
        </div>
        <ul className="list">
          <li>
            <a className="heading" href={curriculumBasisPath()}>
              はじめに
            </a>
          </li>
          <li>
            <p className="heading">IT基礎</p>
            <ul className="list">
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('what-programming')}>
                  プログラミングとは？
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('structure-of-computer')}>
                  コンピュータの仕組み
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('computer-software')}>
                  OSとは？コンピュータのソフトウェア
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('cui-and-gui')}>
                  CUIとGUI
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('file-system')}>
                  ファイル管理
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('internet-and-web')}>
                  インターネットとWeb
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="heading">
              Webアプリ基礎
            </p>
            <ul className="list">
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('what-web-app')}>
                  Webアプリとは？
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('what-server')}>
                  サーバーとは？
                </a>
              </li>
              <li>
                <a
                  className="sub-heading-1"
                  href={curriculumBasisPath('client-and-browser')}>
                  クライアントとブラウザ
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="heading">
              ネットワーク
            </p>
            <ul className="list">
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  IPアドレスとポート
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  HTTP/HTTPS
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  ドメインとDNS
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  SSH
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="heading">
              Webアプリを支える技術
            </p>
            <ul className="list">
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  Git
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  Ruby
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  JavaScript
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  Linux
                </a>
              </li>
              <li>
                <a className="sub-heading-1 unpublished" href="#!">
                  HTML/CSS
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
